import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink, useLocation } from "react-router-dom";

const CustomLink = ({ to, href, text, onClick, className }) => {
  const location = useLocation();

  const isOnHomepage = location.pathname === "/";

  if (isOnHomepage) {
    return (
      <ScrollLink
        className={className ? className : "nav-link"}
        to={to}
        smooth={true}
        duration={500}
        spy={true}
        exact="true"
        offset={-50}
        onClick={onClick}
      >
        {text}
      </ScrollLink>
    );
  }

  return (
    <RouterLink className="nav-link" to={href}>
      {text}
    </RouterLink>
  );
};

export default CustomLink;
