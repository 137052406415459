import React from "react";
import { Route, Routes } from "react-router-dom";
import Nassa from "../components/Nassa/Main";

function Index() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Nassa />} />
      </Routes>
    </>
  );
}

export default Index;
