import { useState, useEffect } from "react";
import axios from "axios";

import DateLocale from "../Helper/DateLocale";
import BookBtn from "../Helper/BookButton";

import "./Modal.css"; // Import the CSS file

const Modal = ({ onClose, apiUrl, title }) => {
  const [datas, setDatas] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    const fetchdatas = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${apiUrl}${page}`
        );
        const { data, pagination } = response.data;
        if (data.length === 0) {
          setHasMore(false);
        } else {
          setDatas((prev) => {
            const mergedData = [...prev, ...data];
            const uniqueData = Array.from(
              new Set(mergedData.map(JSON.stringify))
            ).map(JSON.parse);
            return uniqueData;
          });
        }
      } catch (error) {
        console.error("Error fetching datas:", error);
      }
      setLoading(false);
    };

    fetchdatas();
  }, [page]);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight + 100 && hasMore && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <>
      <div className="modal-overlay" onClick={onClose}></div>
      <div className="modal-container">
        <div className="modal-content">
          {/* Header */}
          <div className="modal-header">
            <h3>{title}</h3>
            <button className="close-button" onClick={onClose}>
              &times;
            </button>
          </div>

          {/* Body */}
          <div className="modal-body" onScroll={handleScroll}>
            <div className="row">
              {datas.length === 0 && !loading
                ? "No data found"
                : datas.map((item, index) => (
                    <div
                      key={index}
                      className="col-4 position-relative orion_card d-flex align-items-stretch"
                    >
                      <img
                        src={`${process.env.REACT_APP_UPLOAD_URL}${item?.imagePath}`}
                        alt={`Events-${item.id}`}
                        id={`Events-${item.id}`}
                        className="img-fluid"
                      />
                      <div className="overlay">
                        <div className="content">
                          <h3>{item?.title}</h3>
                          <p className="lead">{item?.detail}</p>
                          <p className="date">
                            {item?.date && <DateLocale data={item?.date} />}
                          </p>
                          <BookBtn data={item} />
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
            {loading && <p className="loading-text">Loading...</p>}
          </div>

          {/* Footer */}
          {/* <div className="modal-footer">
            <button className="close-button-footer" onClick={onClose}>
              Close
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Modal;
