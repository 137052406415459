import React from "react";

function BookBtn({ data }) {
  if (!data || !data?.date) return null;

  const currentDate = new Date();
  const itemDate = new Date(data?.date);

  if (itemDate > currentDate) {
    return (
      <div className="book_now">
        <a href={data?.btnUrl} className="btn btn_primary">
          {data?.btnText || "RSVP"}
        </a>
      </div>
    );
  }
}

export default BookBtn;
