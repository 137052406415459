import React, { useState } from "react";
import DateLocale from "../Helper/DateLocale";
import Modal from "./Modal";

function Promotions({ data: response, header }) {
  const { data } = response || { data: [] };

  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <section id="promotions" className="orion_events nassa_promotions">
        <div className="container mb-5 pb-5">
          <div className="section_title text-center pb-3 mb-4 mt-5">
            {header?.title && (
              <h2 data-aos="zoom-in" data-aos-duration={1500}>
                {header?.title}
              </h2>
            )}
          </div>
          <div className="row mb-5">
            {data &&
              data?.length > 0 &&
              data?.map((item, index) => (
                <div
                  key={item?.id}
                  className="col-md-4 position-relative orion_card"
                >
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_URL}${item?.imagePath}`}
                    alt="promotions"
                    className="img-fluid w-100 round"
                  />
                  <div className="overlay">
                    <div className="content">
                      <h3>{item?.title}</h3>
                      <p className="lead">{item?.detail}</p>
                      {/* <p className="date">
                      {item?.date && <DateLocale data={item?.date} />}
                    </p> */}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="text-center">
            <button
              className="btn btn_primary"
              onClick={() => setShowModal(true)}
            >
              View All
            </button>
          </div>
        </div>
        {showModal && (
          <Modal
            onClose={() => setShowModal(false)}
            apiUrl={"/nassa/promotions?others=true&limit=6&page="}
            title="All Promotions"
          />
        )}
      </section>
    </>
  );
}

export default Promotions;
