import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

import Header from "./Header";
import HeroGallery from "./HeroGallery";
import Footer from "../Footer/Main";
import About from "./About";
import Highlight from "./Highlight";
import Events from "./Events";
import Promotions from "./Promotions";
import Aos from "aos";
import Gallery from "./Gallery";

function Main() {
  const [reserve, setReserve] = useState(null);
  const [banner, setBanner] = useState(null);
  const [about, setAbout] = useState(null);
  const [highlights, setHighlights] = useState(null);
  const [events, setEvents] = useState(null);
  const [eventsHeader, setEventsHeader] = useState(null);
  const [promotions, setPromotions] = useState(null);
  const [promotionsHeader, setPromotionsHeader] = useState(null);
  const [galleries, setGalleries] = useState(null);
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.replace("#", ""); // Remove the '#' from the hash
      setTimeout(() => {
        scroller.scrollTo(elementId, {
          smooth: true,
          duration: 500,
          offset: -140,
          spy: true,
          exact: "true",
        });
      }, 500);
    }
  }, [location]);

  useEffect(() => {
    Aos.init();
    fetchData();
    Aos.refresh();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [
        reserveResponse,
        bannerResponse,
        aboutResponse,
        highlightsResponse,
        eventsResponse,
        eventsHeaderResponse,
        promotionsResponse,
        promotionsHeaderResponse,
        galleriesResponse,
      ] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/nassa/reserve`),
        axios.get(`${process.env.REACT_APP_API_URL}/nassa/banner`),
        axios.get(`${process.env.REACT_APP_API_URL}/nassa/about`),
        axios.get(`${process.env.REACT_APP_API_URL}/nassa/highlights`),
        axios.get(
          `${process.env.REACT_APP_API_URL}/nassa/events?featured=true&limit=2`
        ),
        axios.get(`${process.env.REACT_APP_API_URL}/nassa/events?title=true`),
        axios.get(
          `${process.env.REACT_APP_API_URL}/nassa/promotions?featured=true&limit=3`
        ),
        axios.get(
          `${process.env.REACT_APP_API_URL}/nassa/promotions?title=true`
        ),
        axios.get(`${process.env.REACT_APP_API_URL}/nassa/gallery`),
      ]);
      setReserve(reserveResponse.data);
      setBanner(bannerResponse.data);
      setAbout(aboutResponse.data);
      setHighlights(highlightsResponse.data);
      setEvents(eventsResponse.data);
      setEventsHeader(eventsHeaderResponse.data);
      setPromotions(promotionsResponse.data);
      setPromotionsHeader(promotionsHeaderResponse.data);
      setGalleries(galleriesResponse.data);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching data:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <div id="home" className="index_wraper nassa_index">
        <Header data={reserve} />
        <HeroGallery data={banner} />
        <About data={about} />
        <Highlight data={highlights} />
        <Events data={events} header={eventsHeader} />
        <Promotions data={promotions} header={promotionsHeader} />
        <Gallery data={galleries} />
        <Footer />
      </div>
    </>
  );
}

export default Main;
