import React, { useState } from "react";
import DateLocale from "../Helper/DateLocale";
import BookBtn from "../Helper/BookButton";

import Modal from "./Modal";

function Events({ data: response, header }) {
  const { data } = response || { data: [] };

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <section id="events" className="orion_events nassa_events">
        <div className="container mb-5 pb-5">
          <div className="section_title text-center pb-3 mb-4 mt-5">
            {header?.title && (
              <h2 data-aos="zoom-in" data-aos-duration={1500}>
                {header?.title}
              </h2>
            )}
          </div>
          <div className="row mb-5">
            {data &&
              data?.length > 0 &&
              data?.map((item, index) => (
                <div
                  className="col-md-6 position-relative orion_card"
                  key={item?.id}
                >
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_URL}${item?.imagePath}`}
                    alt="event1"
                    className="img-fluid round"
                  />
                  <div className="overlay">
                    <div className="content">
                      <h3>{item?.title}</h3>
                      <p className="lead">{item?.detail}</p>
                      <p className="date">
                        {item?.date && <DateLocale data={item?.date} />}
                      </p>
                      <BookBtn data={item} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="text-center">
            <button
              className="btn btn_primary"
              onClick={() => setShowModal(true)}
            >
              View All
            </button>
          </div>
        </div>
        {showModal && (
          <Modal
            onClose={() => setShowModal(false)}
            apiUrl={"/nassa/events?others=true&limit=6&page="}
            title="All Events"
          />
        )}
      </section>
    </>
  );
}

export default Events;
