export default function DateLocale({ data }) {
  if (!data) return null;

  return (
    <div>
      {new Intl.DateTimeFormat("id-ID", {
        timeZone: "UTC",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
        .formatToParts(new Date(data))
        .map((part) =>
          part.type === "literal" && part.value.trim() === "pukul"
            ? ","
            : part.value
        )
        .join("")}
    </div>
  );
}
